import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getNodeById, getNodeChildrenById } from "../../../store/nodes";
import Loader from "../../common/Loader/Loader";
import ErrorPage from "../ErrorPage/ErrorPage";
import classes from "./SkeletonSubsectionPage.module.css";
import notFound from "../../../assets/not_found.png";

function SkeletonSubsectionPage() {
  const arr = [1, 2, 3, 4, 5, 6];
  const history = useHistory();
  return (
    <div className={classes.SkeletonSubsectionPage}>
      <div className={classes.Wrapper}>
        <div className={classes.IconBack} onClick={() => history.push("/")}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825L9.425 14.6L8 16Z" fill="currentColor"/>
          </svg>
        </div>
        <div className={classes.Header}></div>
        <div className={classes.Desciption}>
          <div style={{ width: "100%" }}></div>
          <div style={{ width: "93%" }}></div>
          <div style={{ width: "86%" }}></div>
        </div>
        <div className={classes.List}>
          { arr.map(el => (
            <div className={classes.Card} key={el} id={el}>
              <div className={classes.Image}></div>
              <div style={{ width: "100%", marginBottom: "8px" }}></div>
              <div style={{ width: "93%", marginBottom: "8px" }}></div>
              <div style={{ width: "86%" }}></div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default SkeletonSubsectionPage;
