export const NODE_COLORS_BY_TYPE = {
  subject: 'rgb(255,255,255)',
  section: 'rgb(255,255,255)',
  subsection: 'rgba(112, 128, 144, 1)',
  lesson: 'rgba(128, 0, 128, 1)',
  video: 'rgba(0, 128, 128, 1)'
};
export function transformToNodes(items) {
  const arrayNodes = [];
  const totalArray = [];
  for (const node of items) {
    const tags = node?.tags?.split(",").filter(item => !!item).map(el => el.trim());
    const children = items.filter(item => item.parent === node.id).map(child => child.id);
    const parentType = items.find(item => item.id === node.parent)?.type;
    const grandfather = items.find(item => item.id === node.parent)?.parent;
    const greatgrandfather = items.find(item => item.id === grandfather)?.parent;
    const countChildren = children.length;
    if (node.type === "video" && node.parent) {
      const isShorterPath = parentType === "subsection";
      if (isShorterPath) {
        arrayNodes.push({
          id: node.id,
          name: node.name,
          tags: tags ?? null,
          details: node.details,
          parent: node.parent,
          section: grandfather,
          subsection: node.parent,
          type: node.type,
          isShorterPath,
          group: 5,
          color: NODE_COLORS_BY_TYPE.video
        });
      } else {
        arrayNodes.push({
          id: node.id,
          name: node.name,
          tags: tags ?? null,
          details: node.details,
          parent: node.parent,
          section: greatgrandfather,
          subsection: grandfather,
          type: node.type,
          isShorterPath,
          group: 5,
          color: NODE_COLORS_BY_TYPE.video
        });
      }
    }
    if (node.type === "lesson" && node.parent) {
      arrayNodes.push({
        id: node.id,
        name: node.name,
        parent: node.parent,
        details: node.details,
        section: grandfather ?? null,
        type: node.type,
        countChildren: countChildren ?? null,
        group: 4,
        color: NODE_COLORS_BY_TYPE.lesson
      });
    }
    if (node.type === "subsection" && node.parent) {
      arrayNodes.push({
        id: node.id,
        name: node.name,
        details: node.details,
        countChildren: countChildren ?? null,
        type: node.type,
        parent: node.parent ?? null,
        group: 3,
        color: NODE_COLORS_BY_TYPE.subsection
      });
    }
    if (node.type === "section") {
      arrayNodes.push({
        id: node.id,
        name: node.name,
        details: node.details,
        countChildren: countChildren ?? null,
        type: node.type,
        parent: node.parent ?? null,
        group: 2,
        children,
        color: NODE_COLORS_BY_TYPE.section
      });
    }
    if (node.type === "subject") {
      arrayNodes.push({
        id: node.id,
        name: node.name,
        details: node.details,
        countChildren: countChildren ?? null,
        type: node.type,
        parent: node.parent ?? null,
        group: 1,
        children,
        color: NODE_COLORS_BY_TYPE.subject
      });
    }
  }

  return arrayNodes;
}
