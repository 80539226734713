import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classes from "./CollapsibleSubsection.module.css";
import AnimateHeight from "react-animate-height";
import { useDispatch, useSelector } from "react-redux";
import { getStatesActiveVideo, getStatesActiveSubsection, setActiveVideo, setActiveSection, setActiveSubsection, setVideoModal, setSubsectionModal, getStatesActiveLesson, setActiveLesson } from "../../../store/states";
import { useHistory, useLocation } from "react-router-dom";
import { getNodeById } from "../../../store/nodes";
import useMediaQuery from "../../../hooks/useMediaQuery";
import CollapsibleLesson from "../CollapsibleLesson/CollapsibleLesson";
import CollapsibleVideo from "../CollapsibleVideo/CollapsibleVideo";
function useMediaQueries() {
  // const mobile = useMediaQuery("(max-width: 426px)");
  const mobile = useMediaQuery("(max-width: 769px)");
  return { mobile };
}

function CollapsibleSubsection({ nodes, subsection, lessons, videos, label, countChildren, activeSub, active, isSearch, onCleanField, onZoomLesson }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const activeVideo = useSelector(getStatesActiveVideo());
  const activeLesson = useSelector(getStatesActiveLesson());
  const activeSubsection = useSelector(getStatesActiveSubsection());
  const { pathname } = useLocation();
  const { mobile } = useMediaQueries();
  const [height, setHeight] = useState(0);
  const childrenRef = useRef();
  const parent = useSelector(getNodeById(subsection.parent));

  useEffect(() => {
    if (isSearch) {
      // dispatch(setActiveVideo(""));
      setHeight("auto");
    } else {
      setHeight(0);
      if (active && activeSub) {
        setHeight("auto");
      }
    }
  }, [active, activeSub, isSearch]);

  const handleClick = (subsection) => {
    onCleanField();
    dispatch(setActiveVideo(""));
    dispatch(setActiveLesson(""));
    dispatch(setActiveSubsection(activeSub ? "" : subsection.id));
    if (isSearch) dispatch(setActiveSection(subsection.parent));
    if (pathname !== "/") {
      if (activeSubsection === subsection.id) {
        history.push(`/${subsection.parent}`);
      } else {
        history.push(`/${subsection.parent}/${subsection.id}`);
      }
    } else {
      if (mobile) {
        if (activeSubsection === subsection.id) {
          history.push(`/${subsection.parent}`);
        } else {
          history.push(`/${subsection.parent}/${subsection.id}`);
        }
      }
      // dispatch(setVideoModal(null));
      // dispatch(setSubsectionModal(activeSubsection === subsection.id ? null : subsection.id));
      // onZoomLesson(activeSubsection === subsection.id ? parent : subsection);
      if (activeSubsection === subsection.id) {
        dispatch(setSubsectionModal(null));
        dispatch(setVideoModal(subsection.parent));
        onZoomLesson(parent);
      } else {
        dispatch(setSubsectionModal(subsection.id));
        dispatch(setVideoModal(null));
        onZoomLesson(subsection);
      }
    }
  };
  const sortItems = (items) => {
    const itemsWithOrder = items.filter(l => l.order).sort((a, b) => a.order > b.order ? 1 : -1);
    const itemsWithOutOrder = items.filter(l => !l.order).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, { numeric: true, sensitivity: 'base' }));
    return [...itemsWithOrder, ...itemsWithOutOrder];
  };

  const renderCollapsibleLessons = (items) => {
    return items.map((item) => {
      const children = nodes.filter(el => (item.id === el.parent));
      const sortedChildren = sortItems(children);
      return (<div key={item.id}>
        <CollapsibleLesson
          videos={sortedChildren}
          lesson={item}
          label={item.name}
          active={active}
          activeSub={activeSub}
          activeLesson={activeLesson === item.id}
          onCleanField={onCleanField}
          isSearch={isSearch}
          countChildren={children.length}
          onZoomLesson={onZoomLesson}
        />
      </div>);
    });
  };
  const renderCollapsibleVideos = (items) => {
    return items.map((item) => {
      return (<div key={item.id}>
        <CollapsibleVideo
          video={item}
          label={item.name}
          active={active}
          activeSub={activeSub}
          activeVideo={activeVideo === item.id}
          onCleanField={onCleanField}
          isSearch={isSearch}
          onZoomLesson={onZoomLesson}
          isFromSubsection={true}
        />
      </div>);
    });
  };

  return (
    <div className={classes.CollapsibleSubsection}>
      <button
        className={classes.toggle}
        // onClick={() => setIsOpen(prev => !prev)}
        onClick={() => handleClick(subsection)}
        style={ (!isSearch && activeSub && active && height !== 0) ? { opacity: "1" } : null}
      >
        <span className={classes.toggleText}>{ label }</span>
        <span>{ countChildren }</span>
      </button>
      <AnimateHeight
        id="example-panel"
        duration={800}
        height={height}
      >
        <div
          ref={childrenRef}
          className={classes.content}
        >
          { renderCollapsibleLessons(lessons) }
          { renderCollapsibleVideos(videos) }
        </div>
      </AnimateHeight>
    </div>
  );
}
CollapsibleSubsection.propTypes = {
  label: PropTypes.string,
  activeSub: PropTypes.bool,
  active: PropTypes.bool,
  isSearch: PropTypes.bool,
  countChildren: PropTypes.number,
  onZoomLesson: PropTypes.func,
  onCleanField: PropTypes.func,
  onChange: PropTypes.func,
  subsection: PropTypes.object,
  lessons: PropTypes.arrayOf(PropTypes.object),
  videos: PropTypes.arrayOf(PropTypes.object),
  nodes: PropTypes.arrayOf(PropTypes.object)
};

export default CollapsibleSubsection;
