import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getNodeById } from "../../../store/nodes";
import Loader from "../../common/Loader/Loader";
import classes from "./SkeletonLessonPage.module.css";
import notFound from "../../../assets/not_found.png";
import ErrorPage from "../ErrorPage/ErrorPage";

function SkeletonLessonPage({ delay = 700 }) {
  const history = useHistory();
  const arr = [1, 2, 3, 4, 5, 6];
  return (
    <div className={classes.SkeletonLessonPage}>
      <div className={classes.Wrapper}>
        <div className={classes.IconBack} onClick={() => history.goBack()}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825L9.425 14.6L8 16Z" fill="currentColor"/>
          </svg>
        </div>
        <div className={classes.Header}>
          <div></div>
        </div>
        <div className={classes.Video}></div>
        <div className={classes.TextBlock}>
          <div className={classes.TagsList}>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className={classes.Description}>
            <div style={{ width: "100%", marginBottom: "8px" }}></div>
            <div style={{ width: "93%", marginBottom: "8px" }}></div>
            <div style={{ width: "86%" }}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
SkeletonLessonPage.propTypes = {
  delay: PropTypes.number
};
export default SkeletonLessonPage;
