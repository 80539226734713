import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../common/Loader/Loader";
import { getNodes, getNodesDataStatus, getNodesLoadingStatus, loadNodesList } from "../../../store/nodes";
import { transformNodes } from "../../../utils/transformNodes";
import { filteredNodes, initialfilteredNodes } from "../../../store/states";
import SkeletonSideNavBar from "../SkeletonSideNavBar/SkeletonSideNavBar";
import ErrorPage from "../../page/ErrorPage/ErrorPage";
import notConnection from "../../../assets/not_network.png";

const AppLoader = ({ children }) => {
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const dispatch = useDispatch();
  const nodesDataStatus = useSelector(getNodesDataStatus());
  const nodesStatusLoading = useSelector(getNodesLoadingStatus());

  useEffect(() => {
    if (!nodesDataStatus) dispatch(loadNodesList());
    dispatch(initialfilteredNodes());
  }, [nodesDataStatus]);
  useEffect(() => {
    const handleStatusChange = () => {
      setIsOnline(window.navigator.onLine);
      console.log(window.navigator.onLine);
    };
    window.addEventListener('online', handleStatusChange);
    window.addEventListener('offline', handleStatusChange);

    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isOnline]);
  if (nodesStatusLoading) {
    return (
      <>
        <SkeletonSideNavBar />
        <Loader />
      </>
    );
  };
  if (!isOnline) {
    return (
      <>
        <SkeletonSideNavBar />
        <ErrorPage label="Нет сети" errorMessage="Проверьте соединение с интернетом" source={notConnection}/>;
      </>
    );
  };

  return children;
};
AppLoader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default AppLoader;
