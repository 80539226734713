import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import classes from "./CollapsibleVideo.module.css";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStatesActiveVideo, getStatesActiveSubsection, setActiveVideo, setActiveSection, setActiveSubsection, setVideoModal, setSubsectionModal, setActiveLesson, setMenuState } from "../../../store/states";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { getNodeById } from "../../../store/nodes";
function useMediaQueries() {
  // const mobile = useMediaQuery("(max-width: 426px)");
  const mobile = useMediaQuery("(max-width: 769px)");
  return { mobile };
}

function CollapsibleVideo({ video, label, onCleanField, isSearch, onZoomLesson, isFromSubsection }) {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const activeVideo = useSelector(getStatesActiveVideo());
  const activeSubsection = useSelector(getStatesActiveSubsection());
  const childrenRef = useRef();
  const parent = useSelector(getNodeById(video.parent));
  const { pathname } = useLocation();
  const { mobile } = useMediaQueries();
  const active = activeVideo === video.id;
  const pathToParentShort = `/${video.section}/${video.parent}`;
  const pathToParentLong = `/${video.section}/${video.subsection}/${video.parent}`;
  const pathToVideoShort = `/${video.section}/${video.parent}/video/${video.id}`;
  const pathToVideoLong = `/${video.section}/${video.subsection}/${video.parent}/${video.id}`;

  const handleClick = (video) => {
    if (video.isShorterPath) {
      dispatch(setActiveLesson(""));
    }
    dispatch(setActiveVideo(active ? "" : video.id));
    if (isSearch) {
      onCleanField();
      if (video.isShorterPath) {
        dispatch(setActiveSection(video.section));
        dispatch(setActiveSubsection(video.parent));
      } else {
        dispatch(setActiveSection(video.section));
        dispatch(setActiveSubsection(video.subsection));
        dispatch(setActiveLesson(video.parent));
      }
    }
    if (pathname !== "/") {
      if (active) {
        history.push(video.isShorterPath ? pathToParentShort : pathToParentLong);
      } else {
        history.push(video.isShorterPath ? pathToVideoShort : pathToVideoLong);
      }
      if (mobile) {
        dispatch(setMenuState(false));
      }
    } else {
      if (mobile) {
        if (active) {
          history.push(video.isShorterPath ? pathToParentShort : pathToParentLong);
        } else {
          history.push(video.isShorterPath ? pathToVideoShort : pathToVideoLong);
        }
        dispatch(setMenuState(false));
      }
      if (active) {
        dispatch(setSubsectionModal(video.parent));
        dispatch(setVideoModal(null));
        onZoomLesson(parent);
      } else {
        dispatch(setSubsectionModal(null));
        dispatch(setVideoModal(video.id));
        onZoomLesson(video);
      }
    }
  };

  return (
    <div className={classes.CollapsibleVideo} ref={childrenRef}>
      <button
        className={classes.toggle}
        onClick={() => handleClick(video)}
        style={ (!isSearch && active) ? { opacity: "1" } : null}
      >
        <span className={classes.toggleText}><svg style={{ cursor: "pointer", color: "red", fill: "white" }} width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" role="button">
          <path d="M21.582,6.186c-0.23-0.86-0.908-1.538-1.768-1.768C18.254,4,12,4,12,4S5.746,4,4.186,4.418 c-0.86,0.23-1.538,0.908-1.768,1.768C2,7.746,2,12,2,12s0,4.254,0.418,5.814c0.23,0.86,0.908,1.538,1.768,1.768 C5.746,20,12,20,12,20s6.254,0,7.814-0.418c0.861-0.23,1.538-0.908,1.768-1.768C22,16.254,22,12,22,12S22,7.746,21.582,6.186z M10,15.464V8.536L16,12L10,15.464z"></path>
        </svg></span>
        <span style={{ paddingLeft: "8px" }}>{ label }</span>
      </button>
    </div>
  );
}
CollapsibleVideo.propTypes = {
  video: PropTypes.object,
  label: PropTypes.string,
  isFromSubsection: PropTypes.bool,
  isSearch: PropTypes.bool,
  onZoomLesson: PropTypes.func,
  onCleanField: PropTypes.func
};

export default CollapsibleVideo;
