import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classes from "./CollapsibleLesson.module.css";
import AnimateHeight from "react-animate-height";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStatesActiveSubsection, setActiveVideo, setActiveSection, setActiveSubsection, setVideoModal, setSubsectionModal, getStatesActiveLesson, setActiveLesson } from "../../../store/states";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { getNodeById } from "../../../store/nodes";
import CollapsibleVideo from "../CollapsibleVideo/CollapsibleVideo";
function useMediaQueries() {
  // const mobile = useMediaQuery("(max-width: 426px)");
  const mobile = useMediaQuery("(max-width: 769px)");

  return { mobile };
}

function CollapsibleLesson({ lesson, videos, label, active, activeSub, activeLesson, onCleanField, isSearch, onZoomLesson, countChildren }) {
  const [height, setHeight] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const activeLes = useSelector(getStatesActiveLesson());
  const activeSubsection = useSelector(getStatesActiveSubsection());
  const childrenRef = useRef();
  const parent = useSelector(getNodeById(lesson.parent));
  const { pathname } = useLocation();
  const { mobile } = useMediaQueries();

  useEffect(() => {
    if (isSearch) {
      setHeight("auto");
    } else {
      setHeight(0);
      if (active && activeSub && activeLesson) {
        setHeight("auto");
      }
    }
  }, [active, activeSub, activeLesson, isSearch]);

  const handleClick = (lesson) => {
    dispatch(setActiveVideo(""));
    dispatch(setActiveLesson(activeLesson ? "" : lesson.id));
    if (isSearch) {
      onCleanField();
      dispatch(setActiveSection(lesson.section));
      dispatch(setActiveSubsection(lesson.parent));
    }
    if (pathname !== "/") {
      if (activeLes === lesson.id) {
        history.push(`/${lesson.section}/${lesson.parent}`);
      } else {
        history.push(`/${lesson.section}/${lesson.parent}/${lesson.id}`);
      }
    } else {
      if (mobile) {
        if (activeLes === lesson.id) {
          history.push(`/${lesson.section}/${lesson.parent}`);
        } else {
          history.push(`/${lesson.section}/${lesson.parent}/${lesson.id}`);
        }
      }
      if (activeLes === lesson.id) {
        dispatch(setSubsectionModal(lesson.parent));
        dispatch(setVideoModal(null));
        onZoomLesson(parent);
      } else {
        dispatch(setSubsectionModal(lesson.id));
        dispatch(setVideoModal(null));
        onZoomLesson(lesson);
      }
    }
  };
  const renderCollapsibleVideos = (items) => {
    return items.map((item) => {
      return (<div key={item.id}>
        <CollapsibleVideo
          video={item}
          label={item.name}
          active={active}
          activeSub={activeSub}
          activeLesson={activeLesson === item.id}
          onCleanField={onCleanField}
          isSearch={isSearch}
          onZoomLesson={onZoomLesson}
          isFromSubsection={false}
        />
      </div>);
    });
  };

  return (
    <div className={classes.CollapsibleLesson}>
      <button
        className={classes.toggle}
        onClick={() => handleClick(lesson)}
        style={ (!isSearch && activeSub && active && activeLesson && height !== 0) ? { opacity: "1" } : null}
      >
        <span className={classes.toggleText}>{ label }</span>
        <span>{ countChildren }</span>
      </button>
      <AnimateHeight
        id="example-panel"
        duration={800}
        height={height}
      >
        <div
          ref={childrenRef}
          className={classes.content}
        >
          { renderCollapsibleVideos(videos) }
        </div>
      </AnimateHeight>
    </div>
  );
}
CollapsibleLesson.propTypes = {
  lesson: PropTypes.object,
  videos: PropTypes.arrayOf(PropTypes.object),
  label: PropTypes.string,
  active: PropTypes.bool,
  activeSub: PropTypes.bool,
  isSearch: PropTypes.bool,
  activeLesson: PropTypes.bool,
  countChildren: PropTypes.number,
  onZoomLesson: PropTypes.func,
  onCleanField: PropTypes.func
};

export default CollapsibleLesson;
