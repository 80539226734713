import { combineReducers, configureStore } from "@reduxjs/toolkit";
import statesReducer from "./states";
import nodesReducer from "./nodes";
import userReducer from "./user";

const rootReducer = combineReducers({
  user: userReducer,
  nodes: nodesReducer,
  states: statesReducer
});

export function createStore() {
  return configureStore({
    reducer: rootReducer
  });
}
