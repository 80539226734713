export const NODE_COLORS_BY_TYPE = {
  subject: '#1d2b3d',
  section: '#1d2b3d',
  subsection: '#1d2b3d',
  lesson: '#1d2b3d',
  video: '#1d2b3d'
};
export function transformToTree(items) {
  const arrayNodes = [];
  const rootNodes = items.filter((v) => v.type === 'subject' && v.parent === null);
  for (const node of rootNodes) {
    const countChildren = items.filter((v) => v.type === 'video' && v.parent === node.id).length;
    arrayNodes.push({ id: node.id, name: node.name, group: 1, type: 'subject', parent: null, color: NODE_COLORS_BY_TYPE.subject, show: true, countChildren });
  }
  const ids = [];
  const arrayLinks = [];
  const arraySection = [];
  const arraySubsection = [];
  const arrayAnglesSubsection = [];
  const arrayLesson = [];
  const Radius = 10;
  items.forEach((v) => {
    if (v.type === 'section' && v.parent) arraySection.push(v.id);
    if (v.type === 'subsection' && v.parent) arraySubsection.push(v.id);
    if (v.type === 'lesson' && v.parent) arrayLesson.push(v.id);
  });
  arraySection.forEach((id, idx) => {
    const node = items.find(el => id === el.id);
    const countChildren = items.filter((v) => v.type === 'video' && v.parent === node.id).length;
    const numberGrooup = 2;
    const angleSection = 2 * Math.PI * idx / (arraySection.length);
    const x = Math.floor(Radius * (numberGrooup - 1) * Math.cos(angleSection));
    const y = Math.floor(Radius * (numberGrooup - 1) * Math.sin(angleSection));
    arrayNodes.push({
      id: node.id,
      name: node.name,
      type: node.type,
      group: numberGrooup,
      x,
      y,
      // x: node.position?.x ? node.position.x : x,
      // y: node.position?.y ? node.position.y : y,
      color: NODE_COLORS_BY_TYPE.section,
      countChildren
    });
    arrayLinks.push({
      source: node.parent,
      target: node.id,
      group: 2
    });
    node.children.forEach((id, idx, arr) => {
      const numberGrooup = 3;
      const node = items.find(el => id === el.id);
      const countChildren = items.filter((v) => v.type === 'video' && v.parent === node.id).length;
      const angleSubsection = 2 * Math.PI / (arraySubsection.length + 1);
      const delta = angleSection + arr.length / 2 * angleSubsection - idx * angleSubsection;
      arrayAnglesSubsection.push({
        id: node.id,
        angle: delta
      });
      const x = Math.floor(Radius * (numberGrooup - 1) * Math.cos(delta));
      const y = Math.floor(Radius * (numberGrooup - 1) * Math.sin(delta));
      arrayNodes.push({
        id: node.id,
        name: `${node.name}`,
        x,
        y,
        group: numberGrooup,
        type: node.type,
        collapsed: false,
        color: NODE_COLORS_BY_TYPE.subsection,
        countChildren
      });
      arrayLinks.push({
        source: node.parent,
        target: node.id,
        group: numberGrooup
      });
    });
  });

  for (const node of items) {
    if (node.type === "lesson" && node.parent && arraySubsection.indexOf(node.parent) >= 0) {
      const countChildren = items.filter((v) => v.type === 'video' && v.parent === node.id).length;
      arrayNodes.push({
        id: node.id,
        name: `${node.name}`,
        type: node.type,
        position: node.position,
        x: node.position?.x,
        y: node.position?.y,
        color: NODE_COLORS_BY_TYPE.lesson,
        group: 4,
        collapsed: true,
        countChildren
      });
      arrayLinks.push({
        source: node.parent,
        target: node.id,
        group: 4
      });
    }
  }
  for (const node of items) {
    if (node.type === "video" && node.parent && (arraySubsection.indexOf(node.parent) >= 0 || arrayLesson.indexOf(node.parent) >= 0)) {
    // if (node.type === "video" && node.parent) {
      arrayNodes.push({
        id: node.id,
        name: `${node.name}`,
        type: node.type,
        position: node.position,
        x: node.position?.x,
        y: node.position?.y,
        color: NODE_COLORS_BY_TYPE.video,
        group: 5
      });
      // arrayLinks.push({
      //   source: node.parent,
      //   target: node.id,
      //   group: 5
      // });
    }
  }
  return {
    nodes: arrayNodes,
    links: arrayLinks
  };
}
