import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classes from "./Collapsible.module.css";
import CollabsibleSub from "../CollapsibleSubsection/CollapsibleSubsection";
import AnimateHeight from "react-animate-height";
import { useDispatch, useSelector } from "react-redux";
import { getStatesActiveSubsection, setActiveVideo, setActiveSection, setActiveSubsection, setActiveLesson, setVideoModal, setSubsectionModal } from "../../../store/states";
import { useHistory, useLocation } from "react-router-dom";
import { getNodeById } from "../../../store/nodes";
import useMediaQuery from "../../../hooks/useMediaQuery";

function useMediaQueries() {
  // const mobile = useMediaQuery("(max-width: 426px)");
  const mobile = useMediaQuery("(max-width: 769px)");
  return { mobile };
}

function Collapsible({ nodes, subsections, label, countChildren, active, isSearch, onCleanField, onZoomLesson, section }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const activeSubsection = useSelector(getStatesActiveSubsection());
  const parent = useSelector(getNodeById(section.parent));
  const [height, setHeight] = useState(0);
  const { pathname } = useLocation();
  const childrenRef = useRef();
  const { mobile } = useMediaQueries();

  const sortItems = (items) => {
    const itemsWithOrder = items.filter(l => l.order).sort((a, b) => a.order > b.order ? 1 : -1);
    const itemsWithOutOrder = items.filter(l => !l.order).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, { numeric: true, sensitivity: 'base' }));
    return [...itemsWithOrder, ...itemsWithOutOrder];
  };

  const renderCollapsibleSubs = (items) => {
    return items.map((item, idx) => {
      const children = nodes.filter(el => (item.id === el.parent));
      const lessons = children.filter(el => el.type === "lesson");
      const videos = children.filter(el => el.type === "video");
      const countChildren = children.length;
      const sortedLessons = sortItems(lessons);
      const sortedVideos = sortItems(videos);

      return (<div key={item.id}>
        <CollabsibleSub
          nodes={nodes}
          subsection={item}
          lessons={sortedLessons}
          videos={sortedVideos}
          label={item.name}
          activeSub={activeSubsection === item.id}
          active={active}
          countChildren={countChildren}
          isSearch={isSearch}
          onCleanField={onCleanField}
          onZoomLesson={onZoomLesson}
        />
      </div>);
    });
  };

  useEffect(() => {
    if (isSearch) {
      setHeight("auto");
    } else {
      setHeight(0);
      if (active) {
        setHeight("auto");
      }
    }
  }, [active, isSearch]);

  const handleClick = (section) => {
    onCleanField();
    // dispatch(setVideoModal(""));
    // dispatch(setSubsectionModal(""));
    dispatch(setActiveSubsection(""));
    dispatch(setActiveLesson(""));
    dispatch(setActiveVideo(""));
    dispatch(setActiveSection(active ? "" : section.id));
    onZoomLesson(section);
    if (pathname !== "/") {
      history.push(`/${section.id}`);
      // if (pathname === (`/${subsection.id}`) && !isSearch) {
      //   history.push(`/`);
      // } else {
      //   history.push(`/${subsection.id}`);
      // }
    } else {
      if (mobile) {
        history.push(`/${section.id}`);
      }
      onZoomLesson(active ? parent : section);
      dispatch(setSubsectionModal(null));
      dispatch(setVideoModal(active ? null : section.id));
    }
  };

  return (
    <div className={classes.Collapsible}>
      <button
        className={classes.toggle}
        onClick={() => handleClick(section)}
        style={ !isSearch && active && height !== 0 ? { opacity: "1" } : null}
      >
        { label }
        {"   "}
        {countChildren ? <span>{ countChildren }</span> : null }
      </button>
      <AnimateHeight
        id="example-panel"
        duration={800}
        height={height}
      >
        <div
          ref={childrenRef}
          className={classes.content}
        >
          { renderCollapsibleSubs(subsections) }
        </div>
      </AnimateHeight>
    </div>
  );
}
Collapsible.propTypes = {
  label: PropTypes.string,
  countChildren: PropTypes.number,
  active: PropTypes.bool,
  isSearch: PropTypes.bool,
  onZoomLesson: PropTypes.func,
  section: PropTypes.object,
  onChangeHeight: PropTypes.func,
  onCleanField: PropTypes.func,
  subsections: PropTypes.arrayOf(PropTypes.object),
  nodes: PropTypes.arrayOf(PropTypes.object)
};

export default Collapsible;
