import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.css";

import Main from "./components/layouts/Main";
import Login from "./components/layouts/login";
import NavBar from "./components/ui/navBar";
import AppLoader from "./components/ui/hoc/appLoader";
import SideNavbar from "./components/common/SideNavbar/SideNavbar";
import ScrollUpButton from "./components/common/ScrollUpButton/ScrollUpButton";
import Content from "./components/layouts/Content";
import Loader from "./components/common/Loader/Loader";
import SkeletonSideNavBar from "./components/ui/SkeletonSideNavBar/SkeletonSideNavBar";
import ErrorPage from "./components/page/ErrorPage/ErrorPage";
import notFound from "./assets/not_found.png";
import { useSelector } from "react-redux";
import { getStatesMenuState, getStatesSubsectionModal, getStatesVideoModal } from "./store/states";
import useMediaQuery from "./hooks/useMediaQuery";
import BackDrop from "./components/ui/BackDrop/BackDrop";
import { useCookies } from "react-cookie";
import CookieConsent from "./components/ui/CookieConsent";

function useMediaQueries() {
  const md = useMediaQuery("(max-width: 1006px)");
  // const lg = useMediaQuery("(max-width: 1360px)");

  return { md };
}

function App() {
  const [cookie] = useCookies(["cookieConsent"]);
  const { md } = useMediaQueries();
  const isOpen = useSelector(getStatesMenuState());
  const isSubModal = useSelector(getStatesSubsectionModal());
  // if (md) console.log("MD");
  let styles = {
    paddingLeft: isOpen ? "320px" : "0px"
    // paddingRight: isSubModal ? "400px" : "0px"
  };
  if (md) {
    styles = { paddingLeft: "0px" };
  };
  return (
    <div className="container" style={styles}>
      <AppLoader>
        <SideNavbar />
        { (isOpen && md) ? <BackDrop /> : null }
        { !cookie.cookieConsent && <CookieConsent /> }
        <Switch>
          <Route path="/:sectionId?/:subsectionId?/:lessonId?/:videoId?" component={Content} />
          <Route exact path="/" component={Content} />
          <Route path="/*" >
            <ErrorPage label="Страница не найдена" errorMessage="Проверьте введенный адрес" source={notFound}/>
          </Route >
          {/* <Redirect to="/" /> */}
        </Switch>
      </AppLoader>
      <ScrollUpButton />
    </div>
  );
}

export default App;
