import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import classes from "./Tooltip.module.css";

function Tooltip({ children, label, direction = "top" }) {
  const cls = [
    classes.Tooltip,
    direction === "right" ? classes.right : ""
  ];
  // if (direction === "right") {
  //   cls.push(classes.right);
  // }
  return (
    <div className={cls.join(" ")} data-tooltip={label}>
      {children}
    </div>
  );
}
Tooltip.propTypes = {
  label: PropTypes.string,
  direction: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
};

export default Tooltip;
