import { createSlice } from "@reduxjs/toolkit";
import nodeService from "../services/node.service";
import { transformNodes } from "../utils/transformNodes";
import { transformToNodes } from "../utils/transformToNodes";
import nds from "./response.json";

const nodesSlice = createSlice({
  name: "nodes",
  initialState: {
    // entities: transformNodes(nds),
    entities: null,
    isLoading: true,
    error: null,
    dataLoaded: false
  },
  reducers: {
    nodesRequested: (state) => {
      state.isLoading = true;
    },
    nodesReceived: (state, action) => {
      state.entities = action.payload;
      state.dataLoaded = true;
      state.isLoading = false;
    },
    nodesRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    nodesSaveRequested: (state) => {
      state.isLoading = true;
    },
    nodesSaveSuccess: (state) => {
      state.dataLoaded = true;
      state.isLoading = false;
    },
    nodesSaveRequestFailed: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    }
  }
});

const { actions, reducer: nodesReducer } = nodesSlice;
const { nodesRequested, nodesReceived, nodesRequestFailed, nodesSaveRequested, nodesSaveSuccess, nodesSaveRequestFailed } = actions;

export const loadNodesList = () => async (dispatch, getState) => {
  dispatch(nodesRequested());
  try {
    const { content } = await nodeService.fetchAll();
    const data = transformToNodes(content);
    dispatch(nodesReceived(data));
  } catch (error) {
    dispatch(nodesRequestFailed(error.message));
  }
};
export const saveNodesCoordinates = (payload) => async (dispatch) => {
  // dispatch(nodesSaveRequested());
  try {
    const { content } = await nodeService.saveCoordinates(payload);
    dispatch(nodesSaveSuccess());
  } catch (error) {
    // dispatch(nodesSaveRequestFailed(error.message));
  }
};

export const getNodes = () => (state) => state.nodes.entities;
export const getNodesLoadingStatus = () => (state) =>
  state.nodes.isLoading;
export const getNodesDataStatus = () => (state) => state.nodes.dataLoaded;
export const getNodeById = (id) => (state) => {
  if (state.nodes.entities) {
    return state.nodes.entities.find((node) => node.id === id);
  }
};
export const getNodeChildrenById = (id) => (state) => {
  if (state.nodes.entities) {
    return state.nodes.entities.filter((node) => node.parent === id);
  }
};

export default nodesReducer;
