import React from "react";
import PropTypes from "prop-types";
import classes from "./LessonCard.module.css";
import { useHistory } from "react-router-dom";
import { setActiveVideo, setActiveSection, setActiveSubsection, setActiveLesson } from "../../../store/states";
import { useDispatch, useSelector } from "react-redux";
import { getNodeChildrenById } from "../../../store/nodes";

function LessonCard({ data: lesson }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const children = useSelector(getNodeChildrenById(lesson.id)).slice(0, 9);
  const renderVideosIntoCard = (items) => {
    if (items.length === 0) return "Видео-уроков ещё нет";
    return items.map(item => (
      <img key={item.id} src={`https://img.youtube.com/vi/${item?.details?.code}/0.jpg`} alt={`картинка видео ${item.name}`} loading="lazy"/>
    ));
  };
  const goFullscreenLessonPage = (lesson) => {
    dispatch(setActiveLesson(lesson.id));
    history.push(`/${lesson.section}/${lesson.parent}/${lesson.id}`);
  };
  return (
    <div className={classes.LessonCard}>
      <div className={classes.Thumbnail} onClick={() => goFullscreenLessonPage(lesson)}>
        {/* <img src={`https://img.youtube.com/vi/${lesson?.details?.code}/0.jpg`} alt={`картинка видео ${lesson.name}`} loading="lazy"/> */}
        {renderVideosIntoCard(children)}
      </div>
      <div className={classes.Title}>
        {lesson.name}
      </div>
      {lesson?.details?.description && <div className={classes.Description}>
        {lesson?.details?.description}
      </div>}
    </div>
  );
}

LessonCard.propTypes = {
  data: PropTypes.object
};

export default LessonCard;
