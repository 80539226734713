import { createSlice } from "@reduxjs/toolkit";

const statesSlice = createSlice({
  name: "states",
  initialState: {
    isSearch: false,
    isFilter: false,
    isLoading: false,
    searchValue: "",
    zoom: null,
    filteredNodes: null,
    error: null,
    dataLoaded: false,
    activeSection: null,
    activeSubsection: null,
    activeLesson: null,
    activeVideo: null,
    scientist: null,
    group: null,
    videoSideModal: null,
    subsectionSideModal: null,
    menuShow: true
  },
  reducers: {
    statesSearchValue: (state, action) => {
      state.searchValue = action.payload;
      /* минимальное кол-во символов на поиск */
      if (action.payload.length >= 2) {
        state.isSearch = true;
      }
    },
    statesCleanSearch: (state, action) => {
      state.isSearch = false;
      state.searchValue = "";
    },
    statesZoom: (state, action) => {
      state.zoom = action.payload;
    },
    initialStatesFilterNodes: (state, action) => {
      state.filteredNodes = action.payload;
    },
    statesFilterNodes: (state, action) => {
      state.filteredNodes = action.payload;
    },
    setStateActiveSection: (state, action) => {
      state.activeSection = action.payload;
    },
    setStateActiveSubsection: (state, action) => {
      state.activeSubsection = action.payload;
    },
    setStateActiveLesson: (state, action) => {
      state.activeLesson = action.payload;
    },
    setStateActiveVideo: (state, action) => {
      state.activeVideo = action.payload;
    },
    changeGroupState: (state, action) => {
      state.group = action.payload;
    },
    changeScientistState: (state, action) => {
      state.scientist = action.payload;
    },
    lessonReceived: (state, action) => {
      state.videoSideModal = action.payload;
    },
    subsectionReceived: (state, action) => {
      state.subsectionSideModal = action.payload;
    },
    changeMenuState: (state, action) => {
      state.menuShow = action.payload;
    }
    // statesRequested: (state) => {
    //   state.isLoading = true;
    // },
    // statesReceived: (state, action) => {
    //   state.entities = action.payload;
    //   state.dataLoaded = true;
    //   state.isLoading = false;
    // },
    // statesRequestFailed: (state, action) => {
    //   state.error = action.payload;
    //   state.isLoading = false;
    // }
  }
});

const { actions, reducer: statesReducer } = statesSlice;
const {
  statesRequested,
  statesReceived,
  statesRequestFailed,
  statesSearchValue,
  statesCleanSearch,
  statesZoom,
  statesFilterNodes,
  initialStatesFilterNodes,
  setStateActiveSection,
  setStateActiveSubsection,
  setStateActiveLesson,
  changeGroupState,
  changeScientistState,
  lessonReceived,
  subsectionReceived,
  changeMenuState,
  setStateActiveVideo
} = actions;

// export const loadstatesList = () => async (dispatch, getState) => {
//   dispatch(statesRequested());
//   try {
//     const { content } = await stateService.fetchAll();
//     dispatch(statesReceived(content));
//   } catch (error) {
//     dispatch(statesRequestFailed(error.message));
//   }
// };

export const initialfilteredNodes = () => (dispatch, getState) => {
  const { entities } = getState().nodes;
  dispatch(initialStatesFilterNodes(entities));
};
export const filteredNodes = (payload) => (dispatch, getState) => {
  const { searchValue } = getState().states;
  const { entities } = getState().nodes;
  if (searchValue === "") {
    dispatch(statesCleanSearch());
    dispatch(initialStatesFilterNodes(entities));
  }
  if (searchValue.length < 2) return;
  const nodes = filterNodes(payload, searchValue);
  dispatch(statesFilterNodes(nodes));
};
export const setSearchValue = (value) => (dispatch) => {
  dispatch(statesSearchValue(value));
};
export const cleanField = () => (dispatch, getState) => {
  dispatch(statesCleanSearch());
  const { entities } = getState().nodes;
  dispatch(initialStatesFilterNodes(entities));
};
export const zoom = (node) => (dispatch) => {
  dispatch(statesZoom(node));
};
export const setActiveSection = (value) => (dispatch) => {
  dispatch(setStateActiveSection(value));
};
export const setActiveSubsection = (value) => (dispatch) => {
  dispatch(setStateActiveSubsection(value));
};
export const setActiveLesson = (value) => (dispatch) => {
  dispatch(setStateActiveLesson(value));
};
export const setActiveVideo = (value) => (dispatch) => {
  dispatch(setStateActiveVideo(value));
};
export const setScientistValue = (value) => (dispatch) => {
  dispatch(changeScientistState(value));
};
export const setGroupValue = (value) => (dispatch) => {
  dispatch(changeGroupState(value));
};
export const setMenuState = (value) => (dispatch) => {
  dispatch(changeMenuState(value));
};
export const setSubsectionModal = (value) => (dispatch) => {
  dispatch(subsectionReceived(value));
};
export const setVideoModal = (value) => (dispatch) => {
  dispatch(lessonReceived(value));
};

function filterNodes(data, value) {
  const filteredNodes = value
    ? data.filter(
      (node) =>
        (node.name
          .toLowerCase()
          .indexOf(value.toLowerCase()) !== -1
        ) || (
          node?.tags?.includes(value)
        )
    )
    : data;
  const videos = filteredNodes.filter(el => el.type === "video");
  const mapVideos1 = videos.map(el => el.section);
  const mapVideos2 = videos.map(el => el.subsection);
  const mapVideos3 = videos.map(el => el.parent);
  const lessons = filteredNodes.filter(el => el.type === "lesson");
  const mapLessons = lessons.map(el => el.id);
  const mapLessons1 = lessons.map(el => el.parent);
  const mapLessons2 = lessons.map(el => el.section);
  const mapSubections1 = filteredNodes.filter(el => el.type === "subsection").map(el => el.parent);
  const mapSubections2 = filteredNodes.filter(el => el.type === "subsection").map(el => el.id);
  const mapSections = filteredNodes.filter(el => el.type === "section").map(el => el.id);
  const mapResult = [...mapLessons1, ...mapLessons2, ...mapSubections1, ...mapSubections2, ...mapSections, ...mapVideos1, ...mapVideos2, ...mapVideos3, ...mapLessons];
  const unique = mapResult.filter((el, i) => mapResult.indexOf(el) === i);
  const resultNodes = data.filter(el => unique.includes(el.id));
  const result = [...videos, ...resultNodes];
  return result;
}

export const getStates = () => (state) => state.states.entities;
export const getStatesIsSearch = () => (state) => state.states.isSearch;
export const getStatesSearchValue = () => (state) => state.states.searchValue;
export const getStatesFilteredNodes = () => (state) => state.states.filteredNodes;
export const getStatesZoomNode = () => (state) => state.states.zoom;
export const getStatesLoadingStatus = () => (state) => state.states.isLoading;
export const getStatesDataStatus = () => (state) => state.states.dataLoaded;
export const getStatesActiveSection = () => (state) => state.states.activeSection;
export const getStatesActiveSubsection = () => (state) => state.states.activeSubsection;
export const getStatesActiveLesson = () => (state) => state.states.activeLesson;
export const getStatesActiveVideo = () => (state) => state.states.activeVideo;
export const getStatesScientist = () => (state) => state.states.scientist;
export const getStatesGroup = () => (state) => state.states.group;
export const getStatesMenuState = () => (state) => state.states.menuShow;
export const getStatesSubsectionModal = () => (state) => state.states.subsectionSideModal;
export const getStatesVideoModal = () => (state) => state.states.videoSideModal;

export default statesReducer;
