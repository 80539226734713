import React from "react";
import PropTypes from "prop-types";
import classes from "./SkeletonAsideWindow.module.css";

export default function SkeletonAsideWindow({ payload, onHide, ...rest }) {
  if (payload) {
    return (
      <div className={classes.SkeletonAsideWindow}>
        <div className={classes.Header}>
          <h4>{payload.name}</h4>
          <div className={classes.close} onClick={onHide}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z" fill="currentColor"/>
            </svg>
          </div>
        </div>
        <div className={classes.close}><div></div></div>
        <div className={classes.video}></div>
        <div className={classes.TextBlock}>
          <div className={classes.TagsList}>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div className={classes.description}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    );
  } else return null;
}
SkeletonAsideWindow.propTypes = {
  payload: PropTypes.object,
  onHide: PropTypes.func
};
