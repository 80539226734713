import React from "react";
import PropTypes from "prop-types";
import LessonCard from "../LessonCard";
import classes from "./ItemsList.module.css";
import VideoCard from "../VideoCard/VideoCard";

function ItemsList({ data, type = "video" }) {
  const rendrerVideoList = (items) => {
    if (!items) return;
    return items.map((item) => (
      <VideoCard
        key={item.id}
        data={item}
      />
    ));
  };
  const rendrerLessonList = (items) => {
    if (!items) return;
    return items.map((item) => (
      <LessonCard
        key={item.id}
        data={item}
      />
    ));
  };
  return (
    <div className={classes.ItemsList}>
      { type === "video" && rendrerVideoList(data) }
      { type === "lesson" && rendrerLessonList(data) }
    </div>
  );
}
ItemsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string
};

export default ItemsList;
