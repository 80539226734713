import React, { useEffect } from "react";
import ReactDom from "react-dom";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import logo from "../../../assets/logo.png";
import mini from "../../../assets/logo-mini.webp";
import classes from "./SideNavbar.module.css";
import Collapsible from "../Collabsible/Collapsible";
import SearchField from "../SearchField/SearchField";
import Footer from "../../ui/Footer/Footer";
import { getNodes } from "../../../store/nodes";
import { cleanField, filteredNodes, getStatesActiveSection, getStatesFilteredNodes, getStatesIsSearch, getStatesMenuState, getStatesSearchValue, setActiveVideo, setActiveSection, setActiveSubsection, setMenuState, setSearchValue, zoom } from "../../../store/states";
import SkeletonSideNavBar from "../../ui/SkeletonSideNavBar/SkeletonSideNavBar";
import useMediaQuery from "../../../hooks/useMediaQuery";

function useMediaQueries() {
  const mobile = useMediaQuery("(max-width: 769px)");

  return { mobile };
}

function SideNavbar() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  // const [activeSection, setActiveSection] = useState("");
  const activeSection = useSelector(getStatesActiveSection());
  const isSearch = useSelector(getStatesIsSearch());
  const value = useSelector(getStatesSearchValue());
  const nds = useSelector(getNodes());
  const isOpen = useSelector(getStatesMenuState());
  const { mobile } = useMediaQueries();
  const mechanics = nds.find(n => n?.name.substring(0, 2) === '1.');
  // .sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
  // useEffect(() => {
  //   if (nds) dispatch(filteredNodes(nds));
  // }, [nds]);
  useEffect(() => {
    // if (isSearch) dispatch(setActiveSection(""));
  }, [isSearch]);
  useEffect(() => {
    if (mobile) {
      dispatch(setMenuState(false));
      dispatch(setActiveSection(mechanics.id));
    }
  }, [mobile]);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    dispatch(setSearchValue(value));
    dispatch(filteredNodes(nds));
  };

  const handleCleanFilter = () => {
    dispatch(cleanField());
  };

  const handleZoomNode = (node) => {
    dispatch(zoom(node));
  };
  const handleActiveSection = (node) => {
    activeSection === node
      ? dispatch(setActiveSection(""))
      : dispatch(setActiveSection(node));
  };
  const goToMainPage = () => {
    dispatch(setActiveSection(""));
    dispatch(setActiveSubsection(""));
    dispatch(setActiveVideo(""));
    history.push("/");
  };
  const nodes = useSelector(getStatesFilteredNodes());
  if (nodes) {
    const sections = nodes.filter(el => (el.type === "section") && (el.name !== "Физика"));

    // const sections = nodes.filter(el => (el.type === "section") && (el.name !== "Физика")).sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
    const sortItems = (items) => {
      const itemsWithOrder = items.filter(l => l.order).sort((a, b) => a.order > b.order ? 1 : -1);
      const itemsWithOutOrder = items.filter(l => !l.order).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, { numeric: true, sensitivity: 'base' }));
      return [...itemsWithOrder, ...itemsWithOutOrder];
    };
    const sortedSections = sortItems(sections);

    const renderCollapsibleSections = (items) => {
      return items.map((item, idx) => {
        const children = nodes.filter(el => (item.id === el.parent));
        const sortedChildren = sortItems(children);
        return (<div key={item.id}>
          <Collapsible
            section={item}
            nodes={nodes}
            label={item.name}
            // active={active === idx}
            active={activeSection === item.id}
            onToggle={() => handleActiveSection(item.id)}
            // onToggle={() => setActiveSection(toggle => (toggle === item.id ? "" : item.id))}
            subsections={sortedChildren}
            isSearch={isSearch}
            onCleanField={handleCleanFilter}
            onZoomLesson={handleZoomNode}
          />
        </div>);
      });
    };
    const cls = [
      classes.SideNavbar,
      isOpen ? "" : classes.close
    ];
    const cls2 = [
      classes.Header,
      isOpen ? "" : classes.close
    ];
    return ReactDom.createPortal(
      <>
        <div className={cls.join(" ")}>
          <div className={cls2.join(" ")}>
            <div
              className={classes.IconMenu}
              onClick={() => dispatch(setMenuState(!isOpen))}
            >
              {isOpen
                ? (<svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z" fill="currentColor"/>
                </svg>)
                : (<svg width="18" height="12" viewBox="0 0 18 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 12V10H18V12H0ZM0 7V5H18V7H0ZM0 2V0H18V2H0Z" fill="currentColor"/>
                </svg>)
              }
            </div>
            <img src={logo} alt="logo" className={classes.logo} onClick={goToMainPage}/>
            <div className={classes.section}>Физика</div>
          </div>
          <div className={classes.SearchField}>
            <SearchField
              placeholder="Поиск"
              name="search"
              value={value}
              onChange={handleSearchChange}
              onCleanField={handleCleanFilter}
            />
          </div>
          <div className={classes.Items}>
            <span className={classes.Summary}>Роликов: {nodes.filter(el => (el.type === "video")).length}</span>
            {nodes && sections && nodes.length > 0
              ? <ul>
                { renderCollapsibleSections(sortedSections) }
              </ul>
              : <div className={classes.NotFound}>
                <div>
                  Мы ничего не нашли по вашему запросу
                </div>
              </div>
            }
          </div>
          <div>
            <Footer />
          </div>
        </div>
        {/* { (!isOpen && pathname === "/") */}
        { (!isOpen)
          ? <div
            className={classes.Box}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(setMenuState(!isOpen));
            }}
          >
            <div
              className={classes.IconMenu}
            >
              {isOpen
                ? (<svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z" fill="currentColor"/>
                </svg>)
                : (<svg width="18" height="12" viewBox="0 0 18 12" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0 12V10H18V12H0ZM0 7V5H18V7H0ZM0 2V0H18V2H0Z" fill="currentColor"/>
                </svg>)
              }
            </div>
            <img
              src={mini}
              alt="logo-mini"
              className={classes.Logo}
              // onClick={goToMainPage}
            />
          </div>
          : null
        }
      </>,
      document.getElementById("navmenu")
    );
  } else return <SkeletonSideNavBar />;
}
SideNavbar.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.object),
  onCleanField: PropTypes.func,
  onChange: PropTypes.func,
  onZoomLesson: PropTypes.func,
  isSearch: PropTypes.bool,
  value: PropTypes.string
};

export default SideNavbar;
