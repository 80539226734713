import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getNodeById, getNodeChildrenById } from "../../../store/nodes";
import ErrorPage from "../ErrorPage/ErrorPage";
import classes from "./SubsectionPage.module.css";
import notFound from "../../../assets/not_found.webp";
import SkeletonSubsectionPage from "../SkeletonSubsectionPage/SkeletonSubsectionPage";
import { cleanField, getStatesMenuState, setActiveSection, setActiveSubsection, setSubsectionModal, setVideoModal, zoom } from "../../../store/states";
import useMediaQuery from "../../../hooks/useMediaQuery";
import ItemsList from "../../ui/ItemsList";

function useMediaQueries() {
  const md = useMediaQuery("(max-width: 1124px)");
  const lg = useMediaQuery("(max-width: 1360px)");

  return { md, lg };
}

function SubsectionPage({ delay = 700 }) {
  const { md, lg } = useMediaQueries();
  const dispatch = useDispatch();
  const history = useHistory();
  // const location = useLocation().pathname;
  const { subsectionId } = useParams();
  const subsection = useSelector(getNodeById(subsectionId));
  const children = useSelector(getNodeChildrenById(subsectionId));
  const lessons = children.filter(n => n.type === "lesson");
  const videos = children.filter(n => n.type === "video");
  const [isLoading, setLoading] = useState(true);
  const isOpen = useSelector(getStatesMenuState());

  if (!subsection) return <ErrorPage label="Страница не найдена" errorMessage="Проверьте введенный адрес" source={notFound}/>;

  useEffect(() => {
    if (subsection) {
      const timeout = setTimeout(() => {
        setLoading(false);
      }, delay);
      return () => {
        clearTimeout(timeout);
        setLoading(true);
      };
    }
  }, [delay, subsection]);

  const sortItems = (items) => {
    const itemsWithOrder = items.filter(l => l.order).sort((a, b) => a.order > b.order ? 1 : -1);
    const itemsWithOutOrder = items.filter(l => !l.order).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, { numeric: true, sensitivity: 'base' }));
    return [...itemsWithOrder, ...itemsWithOutOrder];
  };
  const sortedLessons = sortItems(lessons);
  const sortedVideos = sortItems(videos);

  const goParentPage = (subsection) => {
    dispatch(setActiveSubsection(""));
    dispatch(cleanField());
    dispatch(setSubsectionModal(null));
    dispatch(setVideoModal(subsection.parent));
    history.push(`/${subsection.parent}`);
  };
  if (!isLoading) {
    return (
      <div className={classes.SubsectionPage}>
        <div className={classes.wrapper} style={{ margin: !isOpen ? "0 auto" : "0px" }}>
          <div className={classes.IconBack} onClick={() => goParentPage(subsection)}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825L9.425 14.6L8 16Z" fill="currentColor"/>
            </svg>
          </div>
          <h1>{subsection.name}</h1>
          {subsection?.details?.desciption && <div className={classes.desciption}>
            {subsection?.details?.desciption}
          </div>}
          {sortedLessons.length !== 0 && <>
            <h2>Подразделы:</h2>
            <ItemsList data={sortedLessons} type="lesson"/>
          </>}
          {sortedVideos.length !== 0 && <>
            <h2>Видеоуроки:</h2>
            <ItemsList data={sortedVideos} type="video"/>
          </>}
        </div>
      </div>
    );
  } else return <SkeletonSubsectionPage />;
}
SubsectionPage.propTypes = {
  delay: PropTypes.number
};

export default SubsectionPage;
