import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classes from "./AsideSubsectionWindow.module.css";
import SkeletonAsideWindow from "../SkeletonAsideWindow";
import { useDispatch, useSelector } from "react-redux";
import { getNodeById, getNodeChildrenById } from "../../../store/nodes";
import { useHistory } from "react-router-dom";
import { cleanField, setActiveVideo, setActiveSection, setActiveSubsection, setSubsectionModal, setActiveLesson, setVideoModal } from "../../../store/states";

export default function AsideSubsectionWindow({ payload, onHide, delay = 500 }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const data = useSelector(getNodeById(payload));
  const childrenNodes = useSelector(getNodeChildrenById(payload));
  const videos = childrenNodes.filter(n => n.type === "video");
  const lessons = childrenNodes.filter(n => n.type === "lesson");
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      const timeout = setTimeout(() => {
        setLoading(false);
      }, delay);
      return () => {
        clearTimeout(timeout);
        setLoading(true);
      };
    }
  }, [delay, data]);

  const sortItems = (items) => {
    const itemsWithOrder = items.filter(l => l.order).sort((a, b) => a.order > b.order ? 1 : -1);
    const itemsWithOutOrder = items.filter(l => !l.order).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, { numeric: true, sensitivity: 'base' }));
    return [...itemsWithOrder, ...itemsWithOutOrder];
  };
  const sortedLessons = sortItems(lessons);
  const sortedVideos = sortItems(videos);

  const goFullscreenSubsectionPage = (data) => {
    dispatch(setSubsectionModal(null));
    dispatch(cleanField());
    dispatch(setActiveVideo(""));
    if (data.type === "subsection") {
      dispatch(setActiveSection(data.parent));
      dispatch(setActiveSubsection(data.id));
      history.push(`/${data.parent}/${data.id}`);
    } else {
      dispatch(setActiveSection(data.section));
      dispatch(setActiveSubsection(data.parent));
      dispatch(setActiveLesson(data.id));
      history.push(`/${data.section}/${data.parent}/${data.id}`);
    }
  };
  const goBackToParent = () => {
    if (data.type === 'subsection') {
      dispatch(setVideoModal(data.parent));
      dispatch(setSubsectionModal(null));
    }
    if (data.type === 'lesson') {
      dispatch(setVideoModal(null));
      dispatch(setSubsectionModal(data.parent));
    }
  };
  if (!isLoading) {
    return (
      <div className={classes.AsideSubsectionWindow}>
        <div className={classes.Wrapper}>
          <div className={classes.Header}>
            <h4>{data.name}</h4>
            <div className={classes.close} onClick={onHide}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z" fill="currentColor"/>
              </svg>
            </div>
            <button
              className={classes.BackButton}
              onClick={goBackToParent}
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M7 16V3.825L1.4 9.425L0 8L8 0L16 8L14.6 9.425L9 3.825V16H7Z" fill="currentColor"/>
              </svg>
            </button>
          </div>
          {data?.details?.description && <div className={classes.Desciption}>
            {data?.details?.description}
          </div>}
          <button
            onClick={() => goFullscreenSubsectionPage(data)}
            className={classes.Btn}
          >
            Подробнее
          </button>
          {sortedLessons.length !== 0 && <>
            <h3>Подразделы:</h3>
            <div className={classes.List} style={{ marginBottom: "10px" }}>
              {
                sortedLessons.map(lesson => (
                  <button
                    key={lesson.id}
                    className={classes.BtnSection}
                    onClick={() => {
                      dispatch(setSubsectionModal(lesson.id));
                      dispatch(setVideoModal(null));
                    }}
                  >{lesson.name}</button>
                ))
              }
            </div>
          </>}
          {sortedVideos.length !== 0 && <>
            <h3>Видеоуроки:</h3>
            <div className={classes.List}>
              {
                sortedVideos.map(video => (
                  <div key={video.id} className={classes.Card}>
                    <div
                      className={classes.Thumbnail}
                      onClick={() => {
                        dispatch(setSubsectionModal(null));
                        dispatch(setVideoModal(video.id));
                      }}
                    >
                      <img src={`https://img.youtube.com/vi/${video?.details?.code}/0.jpg`} alt={`картинка видео ${video.name}`}/>
                    </div>
                    <div className={classes.TextBlock}>
                      <div className={classes.Title}>
                        {video.name}
                      </div>
                      {video?.details?.description && <div className={classes.Text}>
                        {video?.details?.description}
                      </div>}
                    </div>
                  </div>
                ))
              }
            </div>
          </>}
        </div>
      </div>
    );
  } else return <SkeletonAsideWindow payload={data} onHide={onHide}/>;
}
AsideSubsectionWindow.propTypes = {
  payload: PropTypes.string,
  delay: PropTypes.number,
  onHide: PropTypes.func
};
