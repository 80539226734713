import React from "react";
import classes from "./SkeletonSideNavBar.module.css";

function SkeletonSideNavBar() {
  return (
    <div className={classes.SkeletonSideNavBar}>
      <div className={classes.Header}>
        <div></div>
      </div>
      <div className={classes.Search}></div>
      <div className={classes.Item}><div></div></div>
      <div className={classes.Item}><div></div></div>
      <div className={classes.Item}><div></div></div>
      <div className={classes.Item}><div></div></div>
      <div className={classes.Item}><div></div></div>
      <div className={classes.Item}><div></div></div>
    </div>
  );
}

export default SkeletonSideNavBar;
