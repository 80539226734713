import React, { useState, useEffect } from "react";
import classes from "./ScrollUpButton.module.css";

function ScrollUpButton() {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const handleScroll = event => {
      if (window.scrollY > 100) {
        setShow(true);
      } else {
        setShow(false);
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollUp = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
      { show &&
        <button
          className={classes.ScrollUpButton}
          onClick={handleScrollUp}
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M7 16V3.825L1.4 9.425L0 8L8 0L16 8L14.6 9.425L9 3.825V16H7Z" fill="currentColor"/>
          </svg>
        </button> }
    </>
  );
}

export default ScrollUpButton;
