import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classes from "./AsideWindow.module.css";
import SkeletonAsideWindow from "../SkeletonAsideWindow";
import { useDispatch, useSelector } from "react-redux";
import { cleanField, filteredNodes, setActiveLesson, setActiveSection, setActiveSubsection, setActiveVideo, setMenuState, setSearchValue, setSubsectionModal, setVideoModal } from "../../../store/states";
import { getNodeById, getNodeChildrenById, getNodes } from "../../../store/nodes";
import AnimateHeight from "react-animate-height";
import { useHistory } from "react-router-dom";

export default function AsideWindow({ item, onHide, delay = 500, ...rest }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const payload = useSelector(getNodeById(item));
  console.log(payload);
  const nds = useSelector(getNodes());
  const childrenNodes = useSelector(getNodeChildrenById(item));
  const subsections = childrenNodes.filter(n => n.type === "subsection");
  const [isLoading, setLoading] = useState(true);
  const [height, setHeight] = useState(24);
  useEffect(() => {
    if (payload) {
      const timeout = setTimeout(() => {
        setLoading(false);
      }, delay);
      return () => {
        clearTimeout(timeout);
        setLoading(true);
        setHeight(24);
      };
    }
  }, [delay, payload]);
  if (!isLoading && payload) {
    const { name, tags } = payload;
    const { description, code } = payload?.details;
    const handleSearch = (value) => {
      dispatch(setMenuState(true));
      dispatch(setSearchValue(value));
      dispatch(filteredNodes(nds));
    };
    const renderTags = () => {
      return tags.map((tag, idx) => (
        <span
          onClick={() => handleSearch(tag)}
          key={idx}
        >
          #{tag}
        </span>
      ));
    };
    const sortItems = (items) => {
      const itemsWithOrder = items.filter(l => l.order).sort((a, b) => a.order > b.order ? 1 : -1);
      const itemsWithOutOrder = items.filter(l => !l.order).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, { numeric: true, sensitivity: 'base' }));
      return [...itemsWithOrder, ...itemsWithOutOrder];
    };
    const sortedSubsections = sortItems(subsections);

    const goBackToParent = () => {
      if (payload.type === 'video') {
        dispatch(setVideoModal(null));
        dispatch(setSubsectionModal(payload.parent));
      }
      if (payload.type === 'section') {
        dispatch(setVideoModal(null));
      }
    };
    const goFullscreenSectionPage = (data) => {
      // dispatch(setVideoModal(null));
      dispatch(cleanField());
      dispatch(setActiveVideo(""));
      dispatch(setActiveLesson(""));
      dispatch(setActiveSubsection(""));
      dispatch(setActiveSection(data.id));
      history.push(`/${data.id}`);
    };
    return (
      <div className={classes.AsideWindow}>
        <div className={classes.Header}>
          <h4>{name}</h4>
          <div className={classes.close} onClick={onHide}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z" fill="currentColor"/>
            </svg>
          </div>
          {payload.type === 'video' && <button
            className={classes.BackButton}
            onClick={goBackToParent}
          >
            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M7 16V3.825L1.4 9.425L0 8L8 0L16 8L14.6 9.425L9 3.825V16H7Z" fill="currentColor"/>
            </svg>
          </button>}
        </div>
        {code
          ? <iframe
            className={classes.Video}
            src={`https://www.youtube-nocookie.com/embed/${code}`}
            allowFullScreen="1"
            title={name}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
          : <div
            className={classes.EmptyThumbnail}>
              Скоро появится ролик
          </div>
        }
        <div className={classes.TextBlock}>
          { tags && tags?.length !== 0 ? (
            <div style={{ display: "flex" }}>
              <AnimateHeight
                id="example-panel"
                duration={800}
                height={height}
              >
                <div className={classes.TagsList}>
                  { renderTags() }
                </div>
              </AnimateHeight>
              {height === 24
                ? <span
                  style={{ cursor: "pointer", height: "24px" }}
                  onClick={() => setHeight(height === 24 ? "auto" : 24)}
                >
                &darr;
                </span>
                : <span
                  style={{ cursor: "pointer", height: "24px" }}
                  onClick={() => setHeight(height === 24 ? "auto" : 24)}
                >
                  &uarr;
                </span>}
            </div>
          ) : null }
          {description && <p>
            {description}
          </p>}
          {payload.type === "section" && <button
            onClick={() => goFullscreenSectionPage(payload)}
            className={classes.Button}
          >
            Подробнее
          </button>}
          {payload.type === "section" && !description && <p>
            Скоро здесь появится описание раздела
          </p>}
          {sortedSubsections.length !== 0 && <>
            <h3>Подразделы:</h3>
            <div className={classes.List} style={{ marginBottom: "10px" }}>
              {
                sortedSubsections.map(sub => (
                  <button
                    key={sub.id}
                    className={classes.BtnSection}
                    onClick={() => {
                      dispatch(setVideoModal(null));
                      dispatch(setSubsectionModal(sub.id));
                    }}
                  >{sub.name}</button>
                ))
              }
            </div>
          </>}
        </div>
      </div>
    );
  } else return <SkeletonAsideWindow payload={payload} onHide={onHide}/>;
}
AsideWindow.propTypes = {
  item: PropTypes.string,
  delay: PropTypes.number,
  onHide: PropTypes.func
};
