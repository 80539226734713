import React, { useRef } from "react";
import PropTypes from "prop-types";
import classes from "./SearchField.module.css";

function SearchField({ placeholder, name, value, onChange, onCleanField }) {
  const inputRef = useRef(null);

  const handleFocusClick = () => {
    if (inputRef && inputRef.current) inputRef.current.focus();
  };
  return (
    <div className={classes.SearchField} onClick={handleFocusClick}>
      {/* <span className="input-group-text" id="inputGroup-sizing-default">{label}</span> */}
      <div style={{ width: "18px", height: "18px", marginRight: "15px", marginLeft: "3px" }}>
        <svg style={{ cursor: "pointer", color: "808080" }} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={handleFocusClick} role="button">
          <path d="M16.6 18L10.3 11.7C9.8 12.1 9.225 12.4167 8.575 12.65C7.925 12.8833 7.23333 13 6.5 13C4.68333 13 3.14583 12.3708 1.8875 11.1125C0.629167 9.85417 0 8.31667 0 6.5C0 4.68333 0.629167 3.14583 1.8875 1.8875C3.14583 0.629167 4.68333 0 6.5 0C8.31667 0 9.85417 0.629167 11.1125 1.8875C12.3708 3.14583 13 4.68333 13 6.5C13 7.23333 12.8833 7.925 12.65 8.575C12.4167 9.225 12.1 9.8 11.7 10.3L18 16.6L16.6 18ZM6.5 11C7.75 11 8.8125 10.5625 9.6875 9.6875C10.5625 8.8125 11 7.75 11 6.5C11 5.25 10.5625 4.1875 9.6875 3.3125C8.8125 2.4375 7.75 2 6.5 2C5.25 2 4.1875 2.4375 3.3125 3.3125C2.4375 4.1875 2 5.25 2 6.5C2 7.75 2.4375 8.8125 3.3125 9.6875C4.1875 10.5625 5.25 11 6.5 11Z" fill="#606367"/>
        </svg>
      </div>
      <input
        style={{ marginRight: value ? "17px" : "36px" }}
        ref={inputRef}
        onChange={onChange}
        name={name}
        value={value}
        type="text"
        placeholder={placeholder}
        autoComplete="chrome-off"
        minLength="2"
      />
      {value &&
        <div style={{ marginRight: "5px", width: "17px", height: "17px" }}>
          <svg
            style={{ cursor: "pointer", opacity: "0.7" }}
            onClick={onCleanField}
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            role="button"
          >
            <path d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z" fill="#FFFFFF"/>
          </svg>
        </div>}
    </div>
  );
}
SearchField.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onCleanField: PropTypes.func
};

export default SearchField;
