import React from "react";
import ReactDom from "react-dom";
import { useDispatch } from "react-redux";
import { setMenuState } from "../../../store/states";
import classes from "./BackDrop.module.css";

function BackDrop() {
  const dispatch = useDispatch();
  return ReactDom.createPortal(
    <div
      className={classes.BackDrop}
      onClick={() => dispatch(setMenuState(false))}
    >
    </div>,
    document.getElementById("navmenu")
  );
}

export default BackDrop;
