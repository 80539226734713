import React, { useState, useEffect } from "react";
import Loader from "../../common/Loader/Loader";
import classes from "./Main.module.css";
import { ReactFlowProvider } from "reactflow";
import Flow from "../../common/flow/flow";
import { useDispatch, useSelector } from "react-redux";
import { getNodes, getNodesLoadingStatus } from "../../../store/nodes";
import AsideWindow from "../../common/AsideWindow";
import ScrollUpButton from "../../common/ScrollUpButton/ScrollUpButton";
import SkeletonAsideWindow from "../../common/SkeletonAsideWindow";
import AsideSubsectionWindow from "../../common/AsideSubsectionWindow/AsideSubsectionWindow";
import { getStatesFilteredNodes, getStatesIsSearch, getStatesVideoModal, getStatesSubsectionModal, getStatesZoomNode, setVideoModal, setSubsectionModal, setActiveSection } from "../../../store/states";
import D3Force from "../../common/d3-force/d3-force";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { Redirect } from "react-router-dom";

function useMediaQueries() {
  const mobile = useMediaQuery("(max-width: 769px)");

  return { mobile };
}

function Main() {
  const dispatch = useDispatch();
  const nodes = useSelector(getNodes());
  const zoomNode = useSelector(getStatesZoomNode());
  const filteredNodes = useSelector(getStatesFilteredNodes());
  const isSearch = useSelector(getStatesIsSearch());
  const modalShow = useSelector(getStatesVideoModal());
  const modalSubShow = useSelector(getStatesSubsectionModal());
  const { mobile } = useMediaQueries();
  const [isLoading, setLoading] = useState(true);
  const mechanics = nodes.find(n => n?.name.substring(0, 2) === '1.');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  if (!isLoading && nodes) {
    if (mobile) {
      return <Redirect to={{ pathname: `/${mechanics?.id ?? 'Section_23'}` }}/>;
    };
    return (
      <>
        <div className={classes.Main}>
          {/* <ErrorPage label="Страница не найдена" errorMessage="Проверьте введенный адрес"/> */}
          <div className={classes.Tree}>
            {!isLoading
              // ? <Flow data={nodes} zoomNode={zoomNode} filteredNodes={filteredNodes} isSearch={isSearch}/>
              ? <D3Force data={nodes} lesson={modalShow} payload={modalSubShow} isSearch={isSearch} zoomNode={zoomNode} isModalShow={!!modalShow || !!modalSubShow}/>
              : <Loader />}
          </div>
          <div style={{ paddingRight: modalShow ? "400px" : "0" }}></div>
          {modalShow && <AsideWindow
            item={modalShow}
            onHide={() => dispatch(setVideoModal(null))}
          />}
          <div style={{ paddingRight: modalSubShow ? "400px" : "0" }}></div>
          {modalSubShow && <AsideSubsectionWindow
            payload={modalSubShow}
            onHide={() => dispatch(setSubsectionModal(null))}
          />}
        </div>
      </>
    );
  } return <Loader />;
}

export default Main;
