import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorPage from '../../page/ErrorPage/ErrorPage';
import badGateway from '../../../assets/Bad_gateway.webp';

export default class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // componentDidCatch(error, errorInfo) {
  //   // You can also log the error to an error reporting service
  //   logErrorToMyService(error, errorInfo);
  // }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorPage label="Не удалось загрузить страницу" errorMessage="Попробуйте обновить страницу или зайдите позже" source={badGateway}/>;
    }

    return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
};
