import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getNodeById, getNodeChildrenById } from "../../../store/nodes";
import Loader from "../../common/Loader/Loader";
import LessonsList from "../../ui/ItemsList/ItemsList";
import ErrorPage from "../ErrorPage/ErrorPage";
import classes from "./LessonPage.module.css";
import notFound from "../../../assets/not_found.webp";
import SkeletonSubsectionPage from "../SkeletonSubsectionPage/SkeletonSubsectionPage";
import { cleanField, getStatesMenuState, setActiveLesson, setActiveSection, setActiveSubsection, setSubsectionModal, zoom } from "../../../store/states";
import useMediaQuery from "../../../hooks/useMediaQuery";
import ItemsList from "../../ui/ItemsList";

function useMediaQueries() {
  const md = useMediaQuery("(max-width: 1124px)");
  const lg = useMediaQuery("(max-width: 1360px)");

  return { md, lg };
}

function LessonPage({ delay = 700 }) {
  const { md, lg } = useMediaQueries();
  // if (md) console.log("MD");
  // if (lg) console.log("LG");
  const dispatch = useDispatch();
  const history = useHistory();
  // const location = useLocation().pathname;
  const { lessonId } = useParams();
  const lesson = useSelector(getNodeById(lessonId));
  const children = useSelector(getNodeChildrenById(lessonId));
  const [isLoading, setLoading] = useState(true);
  const isOpen = useSelector(getStatesMenuState());
  if (!lesson) return <ErrorPage label="Страница не найдена" errorMessage="Проверьте введенный адрес" source={notFound}/>;
  // useEffect(() => {
  //   if (lesson) {
  //     dispatch(setActiveSection(lesson.section));
  //     dispatch(setActiveSubsection(lesson.parent));
  //     dispatch(setActiveLesson(lesson.id));
  //   }
  // }, [lesson]);
  useEffect(() => {
    if (lesson) {
      const timeout = setTimeout(() => {
        setLoading(false);
      }, delay);
      return () => {
        clearTimeout(timeout);
        setLoading(true);
      };
    }
  }, [delay, lesson]);
  const goSubsectionPage = (lesson) => {
    dispatch(setActiveLesson(""));
    dispatch(cleanField());
    history.push(`/${lesson.section}/${lesson.parent}`);
  };
  if (!isLoading) {
    return (
      <div className={classes.LessonPage}>
        <div className={classes.wrapper} style={{ margin: !isOpen ? "0 auto" : "0px" }}>
          <div className={classes.IconBack} onClick={() => goSubsectionPage(lesson)}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825L9.425 14.6L8 16Z" fill="currentColor"/>
            </svg>
          </div>
          <h1>{lesson.name}</h1>
          {lesson.details.desciption && <div className={classes.desciption}>
            {lesson?.details?.desciption}
          </div>}
          <h2>Видео:</h2>
          {children.length === 0 && <div className={classes.desciption}>{"Видео-уроков ещё нет"}</div>}
          <ItemsList data={children} type="video"/>
        </div>
      </div>
    );
  } else return <SkeletonSubsectionPage />;
}
LessonPage.propTypes = {
  delay: PropTypes.number
};

export default LessonPage;
