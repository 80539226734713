import React from "react";
import { BarLoader } from "react-spinners";
import classes from "./Loader.module.css";

import loader from "../../../assets/loader.png";

function Loader() {
  return (
    <div className={classes.Loader}>
      <div className={classes.wrapper}>
        <img src={loader} alt="logo_getAclass" />
        <div className={classes.loader}>
          <div className={classes.loaderBar}></div>
        </div>
      </div>
    </div>
  );
}

export default Loader;
