import React from "react";
import classes from "./CookieConsent.module.css";
import { useCookies } from "react-cookie";
import { Link } from "react-router-dom";

function CookieConsent() {
  const [cookie, setCookie] = useCookies(["cookieConsent"]);
  const onChange = () => {
    setCookie("cookieConsent", true, { path: "/" });
  };
  return (
    <div
      className={classes.CookieConsent}
    >
      <div className={classes.Text}>
        Мы используем файлы cookie.{" "}
        <Link to={"/"} className={classes.Link}>Подробнее</Link>
      </div>
      <button
        onClick={onChange}
        className={classes.Button}
      >Xорошо</button>
    </div>
  );
}

export default CookieConsent;
