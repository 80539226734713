import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getNodeById, getNodeChildrenById, getNodes } from "../../../store/nodes";
import AnimateHeight from "react-animate-height";
import Loader from "../../common/Loader/Loader";
import classes from "./SectionPage.module.css";
import notFound from "../../../assets/not_found.webp";
import ErrorPage from "../ErrorPage/ErrorPage";
import SkeletonLessonPage from "../SkeletonLessonPage/SkeletonLessonPage";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { filteredNodes, setSearchValue, setActiveVideo, setActiveSection, setActiveSubsection, setMenuState, getStatesMenuState, setVideoModal, setSubsectionModal, zoom } from "../../../store/states";

function useMediaQueries() {
  const mobile = useMediaQuery("(max-width: 769px)");

  return { mobile };
}

function SectionPage({ delay = 1000 }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const nds = useSelector(getNodes());
  const containerRef = useRef();
  const { sectionId } = useParams();
  const section = useSelector(getNodeById(sectionId));
  const childrenNodes = useSelector(getNodeChildrenById(sectionId));
  const subsections = childrenNodes.filter(n => n.type === "subsection");
  const isOpen = useSelector(getStatesMenuState());
  const [height, setHeight] = useState(24);
  const [isLoading, setLoading] = useState(true);
  const { mobile } = useMediaQueries();

  useEffect(() => {
    if (section) {
      const timeout = setTimeout(() => {
        setLoading(false);
      }, delay);
      return () => {
        clearTimeout(timeout);
        setLoading(true);
        setHeight(24);
      };
    }
  }, [delay, section]);
  const sortItems = (items) => {
    const itemsWithOrder = items.filter(l => l.order).sort((a, b) => a.order > b.order ? 1 : -1);
    const itemsWithOutOrder = items.filter(l => !l.order).sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase(), undefined, { numeric: true, sensitivity: 'base' }));
    return [...itemsWithOrder, ...itemsWithOutOrder];
  };
  const sortedSubsections = sortItems(subsections);

  const goMainPage = (section) => {
    if (mobile) {
      dispatch(setMenuState(true));
    } else {
      dispatch(zoom(section));
      dispatch(setSubsectionModal(null));
      dispatch(setVideoModal(section.id));
      history.push(`/`);
    }
  };
  const goSubsectionPage = (subsection) => {
    dispatch(setVideoModal(null));
    dispatch(setSubsectionModal(subsection.id));
    history.push(`/${subsection.parent}/${subsection.id}`);
  };

  if (!section) return <ErrorPage label="Страница не найдена" errorMessage="Проверьте введенный адрес" source={notFound}/>;

  if (!isLoading) {
    return (
      <div className={classes.SectionPage}>
        <div className={classes.wrapper} ref={containerRef}>
          <div className={classes.IconBack} onClick={() => goMainPage(section)}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825L9.425 14.6L8 16Z" fill="currentColor"/>
            </svg>
          </div>
          <div className={classes.Header}>
            <h1>{section.name}</h1>
          </div>
          <div className={classes.Video}>
            {section?.details.code
              ? <iframe
                src={`https://www.youtube-nocookie.com/embed/${section?.details.code}`}
                allowFullScreen="1"
                title={section.name}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              >
              </iframe>
              : <div
                className={classes.EmptyThumbnail}>
                Скоро появится ролик
              </div>
            }
          </div>
          <div className={classes.TextBlock}>
            {section?.details?.description && <div className={classes.description}>
              {section?.details?.description}
            </div>}
            {!section?.details?.description && <p className={classes.description}>
              Скоро здесь появится описание раздела
            </p>}
            {sortedSubsections.length !== 0 && <>
              <h3>Подразделы:</h3>
              <div className={classes.List} style={{ marginBottom: "10px" }}>
                {
                  sortedSubsections.map(sub => (
                    <button
                      key={sub.id}
                      className={classes.BtnSection}
                      onClick={() => goSubsectionPage(sub)}
                    >{sub.name}</button>
                  ))
                }
              </div>
            </>}
          </div>

        </div>
      </div>
    );
  } else return <SkeletonLessonPage />;
}
SectionPage.propTypes = {
  delay: PropTypes.number
};
export default SectionPage;
