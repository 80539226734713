import React from "react";
import ReactDOM from "react-dom/client";
// eslint-disable-next-line quotes
import "./index.css";
import App from "./app/App";
import { BrowserRouter, Router } from "react-router-dom";
import { createStore } from "./app/store/createStore";
import { Provider } from "react-redux";
import history from "./app/utils/history";
import { CookiesProvider } from "react-cookie";

const store = createStore();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <Router history={history}>
      <BrowserRouter>
        <CookiesProvider>
          <App />
        </CookiesProvider>
      </BrowserRouter>
    </Router>
  </Provider>
);
