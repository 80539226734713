import React from "react";
import PropTypes from "prop-types";
import classes from "./VideoCard.module.css";
import { useHistory } from "react-router-dom";
import { setActiveVideo, setActiveSection, setActiveSubsection } from "../../../store/states";
import { useDispatch } from "react-redux";

function VideoCard({ data: video }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const goFullscreenVideoPage = (video) => {
    dispatch(setActiveVideo(video.id));
    if (video.isShorterPath) {
      history.push(`/${video.section}/${video.parent}/video/${video.id}`);
    } else {
      history.push(`/${video.section}/${video.subsection}/${video.parent}/${video.id}`);
    }
  };
  return (
    <div className={classes.VideoCard}>
      <div className={classes.Thumbnail} onClick={() => goFullscreenVideoPage(video)}>
        <img src={`https://img.youtube.com/vi/${video?.details?.code}/0.jpg`} alt={`картинка видео ${video.name}`} loading="lazy"/>
      </div>
      <div className={classes.Title}>
        {video.name}
      </div>
      {video?.details?.description && <div className={classes.Description}>
        {video?.details?.description}
      </div>}
    </div>
  );
}

VideoCard.propTypes = {
  data: PropTypes.object
};

export default VideoCard;
