import React from "react";
import { useParams, Redirect } from "react-router-dom";
import LessonPage from "../page/LessonPage";
import SectionPage from "../page/SectionPage";
import SubsectionPage from "../page/SubsectionPage";
import VideoPage from "../page/VideoPage";
import ErrorBoundary from "../ui/ErrorBoundary";
import Main from "./Main";

function Content() {
  const params = useParams();
  const { sectionId, subsectionId, lessonId, videoId } = params;
  // console.log(subsectionId);
  // console.log(lessonId);
  // console.log(videoId);

  return (
    <ErrorBoundary>
      {
        sectionId
          ? subsectionId
            ? lessonId !== "video" && lessonId
              ? videoId
                ? <VideoPage />
                : <LessonPage />
              : videoId
                ? <VideoPage />
                : <SubsectionPage />
            : <SectionPage />
          : <Main />
      }
    </ErrorBoundary>
  );
}

export default Content;
