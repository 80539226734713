import React from "react";
import PropTypes from "prop-types";
import classes from "./ErrorPage.module.css";

function ErrorPage({ label, errorMessage, source }) {
  return (
    <div className={classes.ErrorPage}>
      <div className={classes.wrapper}>
        {source && <div className={classes.Image}>
          <img src={source} alt={`картинка для ${label}`}/>
        </div>}
        <h2>{label}</h2>
        <p>{errorMessage}</p>
      </div>
    </div>
  );
}
ErrorPage.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string,
  errorMessage: PropTypes.string
};

export default ErrorPage;
