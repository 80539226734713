import React, { useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { getNodeById, getNodes } from "../../../store/nodes";
import AnimateHeight from "react-animate-height";
import Loader from "../../common/Loader/Loader";
import classes from "./VideoPage.module.css";
import notFound from "../../../assets/not_found.webp";
import ErrorPage from "../ErrorPage/ErrorPage";
import SkeletonLessonPage from "../SkeletonLessonPage/SkeletonLessonPage";
import { filteredNodes, setSearchValue, setActiveVideo, setActiveSection, setActiveSubsection, setMenuState, getStatesMenuState } from "../../../store/states";

function VideoPage({ delay = 1000 }) {
  const nds = useSelector(getNodes());
  const dispatch = useDispatch();
  const containerRef = useRef();
  const history = useHistory();
  // const location = useLocation().pathname;
  const { videoId } = useParams();
  const lesson = useSelector(getNodeById(videoId));
  const isOpen = useSelector(getStatesMenuState());
  const [height, setHeight] = useState(24);

  // const { codeVideo, description } = lesson?.video;
  const [isLoading, setLoading] = useState(true);
  // useEffect(() => {
  //   if (lesson) {
  //     dispatch(setActiveSection(lesson.section));
  //     dispatch(setActiveSubsection(lesson.parent));
  //     dispatch(setActiveVideo(lesson.id));
  //   }
  // }, [lesson]);
  useEffect(() => {
    if (lesson) {
      const timeout = setTimeout(() => {
        setLoading(false);
      }, delay);
      return () => {
        clearTimeout(timeout);
        setLoading(true);
        setHeight(24);
      };
    }
  }, [delay, lesson]);

  const handleSearch = (value) => {
    dispatch(setMenuState(true));
    dispatch(setSearchValue(value));
    dispatch(filteredNodes(nds));
  };
  const goParentPage = (video) => {
    dispatch(setActiveVideo(""));
    if (video.isShorterPath) {
      history.push(`/${video.section}/${video.parent}`);
    } else {
      history.push(`/${video.section}/${video.subsection}/${video.parent}`);
    }
  };

  const renderTags = () => {
    return tags.map((tag, idx) => (
      <span
        key={idx}
        onClick={() => handleSearch(tag)}
      >
        #{tag}
      </span>
    ));
  };
  if (!lesson) return <ErrorPage label="Страница не найдена" errorMessage="Проверьте введенный адрес" source={notFound}/>;
  const { tags } = lesson;
  if (!isLoading) {
    return (
      <div className={classes.VideoPage}>
        <div className={classes.wrapper} ref={containerRef}>
          <div className={classes.IconBack} onClick={() => goParentPage(lesson)}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 16L0 8L8 0L9.425 1.4L3.825 7H16V9H3.825L9.425 14.6L8 16Z" fill="currentColor"/>
            </svg>
          </div>
          <div className={classes.Header}>
            <h1>{lesson.name}</h1>
          </div>
          <iframe
            src={`https://www.youtube-nocookie.com/embed/${lesson?.details.code}`}
            allowFullScreen="1"
            title={lesson.name}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          >
          </iframe>
          <div className={classes.TextBlock}>
            { tags.length !== 0 ? (
              <div style={{ display: "flex", justifyContent: "space-between" }}>

                <AnimateHeight
                  id="example-panel"
                  duration={800}
                  height={height}
                >
                  <div className={classes.TagsList}>
                    { renderTags() }
                  </div>
                </AnimateHeight>
                {height === 24
                  ? <div
                    style={{ cursor: "pointer", height: "24px", width: "24px" }}
                    onClick={() => setHeight(height === 24 ? "auto" : 24)}
                  >
                  &darr;
                  </div>
                  : <div
                    style={{ cursor: "pointer", height: "24px", width: "24px" }}
                    onClick={() => setHeight(height === 24 ? "auto" : 24)}
                  >
                    &uarr;
                  </div>}
              </div>
            ) : null }
            {lesson?.details?.description && <div className={classes.description}>
              {lesson?.details?.description}
            </div>}
          </div>

        </div>
      </div>
    );
  } else return <SkeletonLessonPage />;
}
VideoPage.propTypes = {
  delay: PropTypes.number
};
export default VideoPage;
